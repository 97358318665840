import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Modal is on top of other elements.

  #cb-modal {
    background: white;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    max-height: calc(100vh - 200px - 40px);
    // overflow-y: auto; // Enable vertical scrolling.
    margin-top: 150px;
    position: relative;
  }

  .btn-close-x {
    border: 0;
    position: absolute;
    top: -40px;
    right: 0px;
  }
`;

const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    // Handle the case when isOpen changes
    const handleEscape = (event) => {
      if (event.keyCode === 27) onClose(); // Close modal on ESC key
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    } else {
      document.removeEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <Container>
      <div id="cb-modal">
        <button
          type="button"
          name="close-x-button"
          className="btn-close-x"
          onClick={onClose}
        >
          <span className="fa-regular fa-circle-xmark" />
        </button>
        {children}
      </div>
    </Container>,
    document.body,
  );
};

export { Modal };
